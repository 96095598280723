import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative bg-gray-900 pb-12 md:pb-64 overflow-hidden'>
          <img
            className='absolute top-0 right-0 mr-40'
            src='aurora-assets/headers/dots-background-light.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/headers/light-shadow-right.png'
            alt=''
          />
          <nav className='relative bg-transparent mb-16'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='images/logoipsum-331.svg'
                    alt=''
                  />
                </a>
                <button className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-12 items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'></div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Inicio
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Portafolio
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='#'
                  >
                    Blog
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Solicitar Cotización</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-sm sm:max-w-lg lg:max-w-2xl lg:pt-10 lg:pr-10 mx-auto lg:mx-0'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-semibold text-white mb-6'>
                <span>Ingeniería</span>
                <span className='text-transparent bg-clip-text bg-gradient-to-t from-yellowGreen-200 via-teal-700 to-teal-800'>
                  al alcance de tu negocio.
                </span>
              </h1>
              <p className='text-lg text-white mb-10'>
                Somos una empresa dedicada a invertir por un futuro más
                prometedor.
              </p>
              <div className='flex flex-col xs:flex-row items-center'>
                <span className='inline-block my-4 xs:my-0 xs:mx-4'></span>
              </div>
            </div>
            <img
              className='hidden lg:block absolute top-0 right-0 mr-3 xl:mr-0 max-w-sm xl:max-w-xl w-full'
              src='aurora-assets/headers/header-dark-right-avatars.png'
              alt=''
            />
            <img
              className='lg:hidden block mt-20 w-full max-w-lg mx-auto'
              src='aurora-assets/headers/header-dark-right-avatars.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-5.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-4.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-5.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-4.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Le ofrecemos
                </h1>
                <p className='text-gray-500'>
                  En Inversiones Araya Moya siempre damos la mejor calidad en
                  nuestros servicios
                </p>
                <p className='text-gray-500'>
                  es por eso que estos son unos de los varios servicios que le
                  ofrecemos de manera profesional.
                </p>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='max-w-xs lg:px-4 mx-auto text-center'>
                    <div className='flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-white'>
                      <svg
                        width={33}
                        height={32}
                        viewBox='0 0 33 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.33301 23.4V8.6C4.33301 8.26863 4.60164 8 4.93301 8H22.6786C22.8792 8 23.0665 8.10026 23.1778 8.26718L28.1111 15.6672C28.2455 15.8687 28.2455 16.1313 28.1111 16.3328L23.1778 23.7328C23.0665 23.8997 22.8792 24 22.6786 24H4.93301C4.60164 24 4.33301 23.7314 4.33301 23.4Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Diseño de Sistemas Eléctricos
                      </h5>
                      <p className='text-lg text-gray-500'>
                        Desarrollo eficiente y seguro de infraestructuras
                        eléctricas personalizadas.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='max-w-xs lg:px-4 mx-auto text-center'>
                    <div className='flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-white'>
                      <svg
                        width={33}
                        height={32}
                        viewBox='0 0 33 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M3 14.6667V5.93334C3 5.60197 3.26863 5.33334 3.6 5.33334H12.1114C12.2546 5.33334 12.3931 5.38458 12.5019 5.47779L16.8315 9.1889C16.9402 9.28211 17.0787 9.33334 17.222 9.33334H29.0667C29.398 9.33334 29.6667 9.60197 29.6667 9.93334V14.6667M3 14.6667V26.0667C3 26.398 3.26863 26.6667 3.6 26.6667H29.0667C29.398 26.6667 29.6667 26.398 29.6667 26.0667V14.6667M3 14.6667H29.6667'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Instalaciones Eléctricas Residenciales
                      </h5>
                      <p className='text-lg text-gray-500'>
                        Soluciones eléctricas precisas y seguras para hogares
                        modernos y eficientes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='max-w-xs lg:px-4 mx-auto text-center'>
                    <div className='flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-white'>
                      <svg
                        width={33}
                        height={32}
                        viewBox='0 0 33 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M19 15.9968C19 12.6745 15.9107 9.33334 12.1429 9.33334C11.6965 9.33334 10.2254 9.33335 9.85714 9.33335C6.07005 9.33335 3 12.3167 3 15.9968C3 19.1681 5.27964 21.8219 8.33333 22.4952C8.82341 22.6033 9.33342 22.6603 9.85714 22.6603'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M13.666 15.9968C13.666 19.3191 16.7553 22.6603 20.5232 22.6603C20.9695 22.6603 22.4406 22.6603 22.8089 22.6603C26.596 22.6603 29.666 19.677 29.666 15.9968C29.666 12.8256 27.3864 10.1718 24.3327 9.49843C23.8426 9.39037 23.3326 9.33331 22.8089 9.33331'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Automatización Industrial
                      </h5>
                      <p className='text-lg text-gray-500'>
                        Integración inteligente para optimizar procesos y
                        aumentar la productividad industrial.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='max-w-xs lg:px-4 mx-auto text-center'>
                    <div className='flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-white'>
                      <svg
                        width={33}
                        height={32}
                        viewBox='0 0 33 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3018_21098)'>
                          <path
                            d='M1.66602 26.6667V23C1.66602 19.134 4.80002 16 8.66601 16H13.3327C17.1987 16 20.3327 19.134 20.3327 23V26.6667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M17.666 18.6667V18.6667C17.666 14.9848 20.6508 12 24.3327 12V12C28.0146 12 30.9993 14.9848 30.9993 18.6667V19.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M10.9993 16C13.9449 16 16.3327 13.6122 16.3327 10.6667C16.3327 7.72115 13.9449 5.33334 10.9993 5.33334C8.05383 5.33334 5.66602 7.72115 5.66602 10.6667C5.66602 13.6122 8.05383 16 10.9993 16Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M24.333 12C26.5421 12 28.333 10.2091 28.333 8C28.333 5.79086 26.5421 4 24.333 4C22.1239 4 20.333 5.79086 20.333 8C20.333 10.2091 22.1239 12 24.333 12Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Mantenimiento Preventivo y Correctivo
                      </h5>
                      <p className='text-lg text-gray-500'>
                        Servicios expertos para garantizar la operatividad
                        continua y segura de instalaciones.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='max-w-xs lg:px-4 mx-auto text-center'>
                    <div className='flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-white'>
                      <svg
                        width={33}
                        height={32}
                        viewBox='0 0 33 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M28.333 4.6V27.4C28.333 27.7314 28.0644 28 27.733 28H4.93301C4.60164 28 4.33301 27.7314 4.33301 27.4V4.6C4.33301 4.26863 4.60164 4 4.93301 4H27.733C28.0644 4 28.333 4.26863 28.333 4.6Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M13.2307 11.1796C12.8307 10.9511 12.333 11.2399 12.333 11.7006V20.2994C12.333 20.7601 12.8307 21.0489 13.2307 20.8204L20.7547 16.5209C21.1578 16.2906 21.1578 15.7094 20.7547 15.4791L13.2307 11.1796Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Eficiencia Energética
                      </h5>
                      <p className='text-lg text-gray-500'>
                        Estrategias innovadoras para reducir costos y minimizar
                        el impacto ambiental.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='max-w-xs lg:px-4 mx-auto text-center'>
                    <div className='flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-white'>
                      <svg
                        width={33}
                        height={32}
                        viewBox='0 0 33 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3018_21112)'>
                          <path
                            d='M11 24L15 28L21.6667 21.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M26.9993 23.4763C28.991 22.6962 30.9993 20.9185 30.9993 17.3333C30.9993 12 26.5549 10.6667 24.3327 10.6667C24.3327 8 24.3327 2.66666 16.3327 2.66666C8.33268 2.66666 8.33268 8 8.33268 10.6667C6.11046 10.6667 1.66602 12 1.66602 17.3333C1.66602 20.9185 3.67438 22.6962 5.66602 23.4763'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Consultoría en Energías Renovables
                      </h5>
                      <p className='text-lg text-gray-500'>
                        Asesoramiento especializado para la implementación
                        exitosa de fuentes energéticas sostenibles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-16 px-2 text-xs uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                TESTIMONIALS
              </span>
              <h1 className='font-heading tracking-tight text-3xl xs:text-4xl sm:text-5xl font-semibold mb-16'>
                <span>El gobierno chileno se siente</span>
                <span className='inline-block ml-2 pb-px px-2 bg-yellowGreen-600 text-white rounded-lg'>
                  muy feliz
                </span>
                <span>
                  , de haber creado una relación estrecha con Inversiones Araya
                  Moya y ser su fiel cliente.
                </span>
              </h1>
              <div className='mb-16'>
                <span className='block text-xl font-semibold'>
                  Gabriel Boric
                </span>
                <span className='text-base text-gray-500'>
                  Presidente de chile
                </span>
              </div>
              <div className='flex justify-center items-center'>
                <button className='inline-block pt-1.5 w-4 transform hover:scale-x-150 mr-2 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
                <button className='inline-block pt-1.5 w-4 mr-2 transform hover:scale-x-150 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
                <button className='inline-block pt-1.5 w-4 transform hover:scale-x-150 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden'>
          <div className='flex flex-wrap'>
            <div className='relative w-full md:w-5/12 pb-52 xs:pb-64 md:pb-80 xl:pb-112 pt-12 md:pt-28 px-6 lg:pr-10 bg-gray-800'>
              <div className='mx-auto max-w-md'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-white bg-gray-700 rounded-full'>
                  FAQ
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                  FAQs
                </h1>
                <p className='text-gray-400'>
                  Todo lo que necesitas saber sobre nuestros servicios y
                  consultorias.
                </p>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='aurora-assets/contact/line-half-circle-dark.png'
                alt=''
              />
            </div>
            <div className='w-full md:w-7/12 px-6 py-28 md:pl-12 xl:pl-24 bg-gray-900'>
              <div className='max-w-lg mx-auto lg:mx-0 lg:max-w-xl'>
                <div>
                  <button className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        ¿Que es Inversiones Araya Moya?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Inversiones Araya Moya es una empresa chilena que se
                        dedica a la inversión en activos sobre la ingeniería
                        electrica.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        ¿Como puedo contactar a Inversiones Araya Moya?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Si lo que desea es contactar a alguno de nuestros
                        asesores puede hacerlo enviando un correo electronico a
                        ingenieria@inversionesarayamoya.com
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        ¿Que costos tienen nuestros servicios?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Nuestros precios pueden variar segun el caso y el
                        servicio que se preste, para conocer el precio o
                        realizar una cotización porfavor escribanos a
                        gerencia@inversionesarayamoya.com
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button className='group flex items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        ¿Puedo contar con sus servicios en otro país?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Por el momento Inversiones Araya Moya unicamente presta
                        servicios en Chile y en Colombia, pero no dude en
                        contactarse con nosotros en cuanto estemos disponibles
                        en su país.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/team/shadow-green.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
              Conozca a nuestro equipo.
            </h1>
            <p className='text-lg text-gray-500 mb-20'>
              Nuestro equipo esta conformado por personas que se han formado en
              el campo de la ingeniería por años y años de dedicación y esfuerzo
              y hoy estan a su disposición.
            </p>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-6.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-5.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-4.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-7.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Ingeniero en Software
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-8.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Ingeniera Electrica
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-3.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Ingeniera Electrica
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-2.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Ingeniera Electrica
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-1.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Ingeniero Electrico
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-gray-900'>
          <div className='mb-12 pb-24 border-b border-gray-800'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-16'>
                  <div>
                    <img
                      className='block h-8 mb-4'
                      src='images/logoipsum-331.svg'
                      alt=''
                    />
                    <span className='block text-lg text-gray-400'>
                      Aurora make collecting fun again.
                    </span>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 ml-auto px-4'>
                  <div className='flex flex-wrap -mx-4 -mb-8'>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Product
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Servicios
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Soluciones
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Precios Base
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Consultorias{' '}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Company
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Sobre Nosotros
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Nuestra Misión
                          </a>
                        </li>
                        <li className='flex items-center mb-6'>
                          <a
                            className='inline-block mr-2 text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            <span>Empleos</span>
                          </a>
                          <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                            <svg
                              width={8}
                              height={8}
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={4} cy={4} r={3} fill='white' />
                            </svg>
                            <span className='ml-1 text-xs text-gray-50'>
                              Contratando
                            </span>
                          </span>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Contacto Soporte
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Resources
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Cursos
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Blog
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Centro de ayuda
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Centro de soporte
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container px-4 mx-auto'>
            <div className='md:flex items-center justify-between'>
              <p className='text-gray-400 mb-10 md:mb-0'>
                Hecho con amor © 2024 Inversiones Araya Moya . Todos los
                derechos reservados.
              </p>
              <div className='flex items-center'>
                <a
                  className='inline-flex w-10 h-10 p-2 mr-3 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
                <a
                  className='inline-flex w-10 h-10 p-2 mr-3 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
                <a
                  className='inline-flex w-10 h-10 p-2 mr-3 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
                <a
                  className='inline-flex w-10 h-10 p-2 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

